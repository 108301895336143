import { useEffect, useState } from 'react';

export function useDelayedLoader(loading: boolean, duration = 1000) {
  const [state, setState] = useState(loading);

  useEffect(() => {
    if (!loading) {
      setState(false);

      return;
    }

    const timer = setTimeout(() => {
      setState(true);
    }, duration);

    return () => {
      clearTimeout(timer);
    };
  }, [loading]);

  return state;
}
