import type { Reducer } from 'react';
import { useReducer } from 'react';

import { PepitaSnackbar, usePepitaSnackbar } from 'src/libs/ui/pepita-snackbar';

import type { ValidationError } from 'src/utils/form-validators';

export type SimpleFormStatusState<D> =
  | {
      type: 'ok' | 'loading' | 'trovakasa';
    }
  | {
      type: 'success';
      message?: string;
    }
  | {
      type: 'error';
      errors: ValidationError<D>[];
      message?: string;
    };

export type SimpleFormStatusAction<D> =
  | 'ok'
  | 'loading'
  | 'success'
  | {
      type: 'success';
      message: string;
    }
  | {
      type: 'error';
      errors: ValidationError<D>[];
      message?: string;
    };

export function useSimpleFormStatus<D>() {
  const [state, dispatch] = useReducer<
    Reducer<SimpleFormStatusState<D>, SimpleFormStatusAction<D>>
  >(
    (_, action) => {
      if (typeof action === 'string') {
        return {
          type: action,
        };
      }

      return action;
    },
    { type: 'ok' }
  );
  const setSnackbar = usePepitaSnackbar();

  function setStatus(action: SimpleFormStatusAction<D>) {
    if (typeof action !== 'string' && action.message) {
      setSnackbar(
        <PepitaSnackbar variant={action.type}>{action.message}</PepitaSnackbar>
      );
    }

    dispatch(action);
  }

  return [state, setStatus] as [
    SimpleFormStatusState<D>,
    (action: SimpleFormStatusAction<D>) => void,
  ];
}
