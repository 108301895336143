import type { BaseSyntheticEvent, ChangeEvent } from 'react';
import { useEffect, useState } from 'react';

import { useDelayedLoader } from 'src/hooks/useDelayedLoader';
import type {
  SimpleFormStatusAction,
  SimpleFormStatusState,
} from './useSimpleFormStatus';
import { useSimpleFormStatus } from './useSimpleFormStatus';

import type { ValidationSchema } from 'src/utils/form-validators';
import {
  getValidationErrors,
  isFieldInvalid,
  validateField,
  validateFields,
} from 'src/utils/form-validators';
import { produce } from 'src/utils/immer';

export type SimpleForm<D> = {
  status: SimpleFormStatusState<D>;
  setStatus: (action: SimpleFormStatusAction<D>) => void;
  handleSubmit: (extra?: any) => void;
  handleChange: (update: (data: D) => void) => void;
  handleValidateTextInput: (name: keyof D) => (evt: BaseSyntheticEvent) => void;
  handleTextInputChange: (
    name: keyof D
  ) => (evt: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  handleCheckboxChange: (
    name: keyof D
  ) => (evt: ChangeEvent<HTMLInputElement>) => void;
  isNotValid: (name: keyof D) => boolean;
  getErrorMessage: (name: keyof D) => string | undefined;
  data: Partial<D>;
  submitButtonDisabled: boolean;
  submitButtonLoading: boolean;
};

export function useSimpleForm<D, R = any>({
  initialData,
  schema,
  onSubmit,
  onSuccess,
  onError,
}: {
  initialData: Partial<D>;
  schema: ValidationSchema<D>;
  onSubmit: (data: D, extra?: any) => Promise<R>;
  onSuccess: (data: D, submitResponse: R, extra?: any) => string;
  onError: (error: Error) => string;
}): SimpleForm<D> {
  const [data, setData] = useState(initialData);

  useEffect(() => {
    setData(initialData);
  }, [initialData]);

  const [status, setStatus] = useSimpleFormStatus<D>();

  function handleSubmit(extra?: any) {
    if (status.type === 'loading') return;

    const errors = validateFields(data as D, schema);

    if (errors) {
      setStatus({
        type: 'error',
        errors,
      });

      return;
    }

    setStatus('loading');

    const promise = onSubmit(data as D, extra);

    function handleSuccess(submitResponse: R) {
      const message = onSuccess(data as D, submitResponse, extra);

      setStatus({
        type: 'success' as const,
        message,
      });
    }

    function handleError(error) {
      const message = onError(error);

      setStatus({
        type: 'error' as const,
        errors: [],
        message,
      });
    }

    promise.then(handleSuccess, handleError);
  }

  const handleChange = (update: (data: D) => void) => {
    const newData = produce(data, update) as D;

    setData(newData);

    // We only remove the errors during change
    if (status.type === 'error') {
      const errors = validateFields(newData, schema);

      if (!errors) {
        setStatus('ok');
      } else {
        const prev = new Set(status.errors.map((e) => e.path));

        setStatus({
          type: 'error',
          errors: errors.filter((e) => prev.has(e.path)),
        });
      }
    }
  };

  const handleValidateTextInput =
    (name: keyof D) => (evt: BaseSyntheticEvent) => {
      const value = evt.target.value;

      const error = validateField(name, data as D, schema);

      if (status.type === 'error') {
        const errors = validateFields(data, schema);
        const prev = new Set(status.errors.map((e) => e.path));

        if (value && errors) {
          setStatus({
            type: 'error',
            errors: (error || []).concat(
              errors.filter((e) => prev.has(e.path))
            ),
          });
        }
      } else if (value && error) {
        setStatus({
          type: 'error',
          errors: error,
        });
      }
    };

  const handleTextInputChange =
    (name: keyof D) =>
    (evt: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      handleChange((data) => {
        data[name as string] = evt.target.value;
      });
    };

  const handleCheckboxChange =
    (name: keyof D) => (evt: ChangeEvent<HTMLInputElement>) => {
      handleChange((data) => {
        data[name as string] = evt.target.checked;
      });
    };

  function isNotValid(name: keyof D) {
    return status.type === 'error' && isFieldInvalid(status.errors, name);
  }

  function getErrorMessage(name: keyof D): string | undefined {
    if (!isNotValid(name) || status.type !== 'error') {
      return undefined;
    }

    return getValidationErrors(status.errors, name as string);
  }

  const isDisabled = status.type === 'loading';
  const isLoading = useDelayedLoader(isDisabled);

  return {
    status,
    setStatus,
    handleSubmit,
    handleChange,
    handleValidateTextInput,
    handleTextInputChange,
    handleCheckboxChange,
    isNotValid,
    getErrorMessage,
    data,
    submitButtonDisabled: isDisabled,
    submitButtonLoading: isLoading,
  };
}
