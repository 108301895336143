import { nonNullable } from './common';

export type ValidationError<D> = {
  path: keyof D;
  message: string;
  valid: boolean;
};

export type ValidationResult<D> = ValidationError<D>[] | null;

export type Validator<D> = (
  data: D,
  key: keyof D
) => Omit<ValidationError<D>, 'path'>;

export type ValidationSchema<D> = Partial<Record<keyof D, Validator<D>[]>>;

const regexpEmail =
  /^[a-z0-9!#$%&'*+=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/gi;
const regexpPhoneNumber = /^(\+)?[\s\d()\-/.]+$/g;

export const validateFields = <D>(
  data: D,
  schema: ValidationSchema<D>
): ValidationResult<D> => {
  const keysToValidate = Object.keys(schema) as (keyof ValidationSchema<D>)[];
  const results = keysToValidate.flatMap((key) => {
    return validateField(key, data, schema);
  });

  const errors = results.filter(nonNullable);

  return !errors.length ? null : errors;
};

export const validateField = <D>(
  key: keyof D,
  data: D,
  schema: ValidationSchema<D>
): ValidationResult<D> | null | undefined => {
  const functions = schema[key];

  const results = functions?.map((f) => {
    return {
      ...f(data, key),
      path: key,
    };
  });

  const errors = results?.filter((e) => e.valid !== true);

  return !errors?.length ? null : errors;
};

export const isFieldInvalid = <D>(
  errors: ValidationResult<D>,
  name: keyof D
): boolean => {
  if (!errors) return false;

  const fields = errors.map((error) => error.path);

  return fields.includes(name) ? true : false;
};

export const isEmail = <D>(message: string) => {
  return (data: D, key: keyof D) => {
    const value = data[key];

    return {
      valid:
        (typeof value === 'string' && Boolean(value.match(regexpEmail))) ||
        !value,
      message,
    };
  };
};

export const isPhoneNumber = <D>(message: string) => {
  return (data: D, key: keyof D) => {
    const value = data[key];

    return {
      valid:
        (typeof value === 'string' &&
          value.length >= 6 &&
          Boolean(value.match(regexpPhoneNumber))) ||
        !value,
      message,
    };
  };
};

export const isRequired = <D>(message: string) => {
  return (data: D, key: keyof D) => {
    const value = data[key];
    // check if value isn't an empty string
    const isNotEmptyValue = Boolean(
      typeof value === 'string'
        ? value.toString().trim().length
        : typeof value === 'number' || Boolean(value)
    );

    return {
      valid: isNotEmptyValue,
      message,
    };
  };
};

export const isTruthy = <D>(message: string) => {
  return (data: D, key: keyof D) => {
    const value = data[key];

    return {
      valid: Boolean(value),
      message,
    };
  };
};

export const oneOf = <D>(values: unknown[], message = '') => {
  return (data: D, key: keyof D) => {
    const value = data[key];

    return {
      valid: values.includes(value),
      message,
    };
  };
};

export const almostOneOf = <D>(message = '') => {
  return (data: D, key: keyof D) => {
    const value = data[key] || [];

    let valid = false;

    Object.keys(value).forEach((v) => {
      if (value[v]) {
        valid = true;
      }
    });

    return {
      valid,
      message,
    };
  };
};

export const getValidationErrors = <D>(
  errors: ValidationResult<D>,
  name: string
) => {
  const error = errors?.find((error) => error.path === name);

  return error ? error.message : '';
};

export const isPhoneOrEmailRequired = <D>(message = 'required') => {
  return (data: D, key: keyof D) => {
    const phone = data['phone'];
    const required = isRequired<D>(message);

    let result = {
      valid: true,
      message: '',
    };

    if (!phone) {
      result = required(data, key);
    }

    return result;
  };
};

export const phoneWhenEmail = <D>(message = 'required') => {
  return (data: D, key: keyof D) => {
    const email = data['email'];
    const required = isRequired<D>(message);
    let result = {
      valid: true,
      message: '',
    };

    if (!email) {
      result = required(data, key);
      result.message = ''; // don't show error message for this field
    }

    return result;
  };
};
